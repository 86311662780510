import React from 'react'
import Container from 'react-bootstrap/Container'

const NotFound = () => {
    return (
        <main>
            <Container>
                <h1>404</h1>
            </Container>
        </main>
    );
};

export default NotFound